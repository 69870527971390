
  import {defineComponent, inject, PropType, ref} from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import qs from "qs";
import {getCartInfo} from "@/api/useChangeShopCar";
  import axios from "@/api/axios";

export default defineComponent({
  name: "slotFooter",
  props: {
    tid: {
      type: String,
      default: "",
    },
    nclassId: {
      type: String,
      default: "0",
    },
    pid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    viewCartText: {
      type: String,
      default: "查看购物车",
    },
    lotid: {
      type: String,
      default: "0"
    },
    tcid: {
      type: String,
      default: "0"
    },
    stock: {
      type: Number,
      default: null
    },
    buttonType:{
      type:Number,
      default:1
    },
    checkValue:{
      type:Number,
      default:0
    }
  },
  setup(props) {
    //  add shop car
    const { addShopCar,storgeBackstage } = useShopCar();
    const userInfo = inject("userInfo") as any;
    // console.log(userInfo.id,"sdfsdfs")
    //查看购物车
    function toViewCart() {
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可查看购物车</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        router.push({name: "ShopCar"});
      }
    }
    //去申请试用
    function detailToApplyOrder(pid: any, lotid: any) {
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          // content: '<p class="modal_p"><span>您还未登录，请注意</br>1、已成单用户&emsp;2、已申请过此产品</br>3、累计申请过两个以上产品的用户</br></span><span style="color: red">登录后将不再显示试用按钮。<span></p>',
          content: '<div>登录后才能申请试用产品</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        storgeBackstage(pid, 0, 1,lotid,0,1,0);
        //子组件中修改父组件传递过来的值
        this.$emit('update:buttonType', 1)
      }
    }
    function collectAction() {
      const formData = new FormData();
      formData.append("id", props.pid);
      formData.append("type", props.type);
      api
        .post("/M/Server/Collection", formData, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res) {
            Toast({
              title: res.data.msg,
              type: "success",
            });
            const collectImg = $('#collectImg').attr('src')
            $("#collect img").prop(
              "src",
              collectImg
            );
          }
        })
        .catch((err) => {
          Toast({
            title: err.message || "收藏失败，请联系管理员",
            type: "error",
          });
        });
    }

    function standarddownloadcheck() {
      /*const formData = new FormData();
      formData.append("id", props.pid);
      console.log(props.pid)
      Toast({
        title: "下载请移步至电脑端：https://www.bzwz.com/",
        type: "warning",
        duration: 3000,
      });*/
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可下载标准</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        axios.get("/M/Product/DownloadNoUseScore?id="+props.pid)
                .then(res => {
                  if (res.data.success) {
                    const link = document.createElement('a');
                    link.href = res.data.link;
                    link.setAttribute('download', res.data.str);
                    document.body.appendChild(link);
                    link.click();
                  }else{
                    Toast({
                      title: res.data.msg,
                      type: "warning",
                      duration: 3000,
                    });
                  }
                })
                .catch(error => {
                  console.error(error);
                });
      }
    }

    return {
      // handleAddCart,
      collectAction,
      standarddownloadcheck,
      toViewCart,
      detailToApplyOrder,
      addShopCar,
      storgeBackstage,
      userInfo
    };
  },
});
